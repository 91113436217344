<template>
  <div class="form">
    <div class="row pb-4">
      <template v-for="input in inputs">
        <component
          :is="`${input.type}-input`"
          :key="input.id"
          :input="input"
          :options="options && options[input.id]"
          :class="`${
            typeof input.col != 'undefined'
              ? input.col === 0
                ? 'col'
                : 'col-'.concat(input.col)
              : 'col-12'
          }`"
          @change="inputChanged"
        />
      </template>
    </div>
    <div
      class="pt-3 border-top d-flex justify-content-between align-items-center"
    >
      <button class="btn btn-light px-4" @click="close()">Cancel</button>
      <button class="btn btn-success px-4" @click="save()">
        <i class="fas fa-save mr-2"></i>
        Save
      </button>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/inputs/Text";
import TextareaInput from "@/components/inputs/Textarea";
import SelectInput from "@/components/inputs/Select";
import SwitchInput from "@/components/inputs/Switch";
import CalendarInput from "@/components/inputs/Calendar";
import EnumInput from "@/components/inputs/Enum";
import FileInput from "@/components/inputs/File";

export default {
  name: "apparatus-form-component",
  props: ["options", "apparatus", "sharer", "site"],
  components: {
    TextInput,
    TextareaInput,
    SelectInput,
    SwitchInput,
    CalendarInput,
    EnumInput,
    FileInput,
  },
  data() {
    return {
      inputs: [
        {
          type: "file",
          label: "Image",
          id: "images",
          model: null,
        },
        {
          type: "text",
          label: "Name",
          id: "name",
          model: null,
        },
        {
          type: "select",
          label: "Type",
          id: "apparatustype",
          model: null,
          col: 6,
        },
        {
          type: "enum",
          label: "Location",
          id: "location",
          model: null,
          col: 6,
        },
      ],
    };
  },
  methods: {
    close() {
      this.$parent.close();
    },
    async save() {
      this.$parent.$emit("toggleSpinner", true);

      let body = {};

      this.inputs
        .filter((input) => input.type != "file")
        .forEach((input) => {
          body[input.id] =
            typeof input.model == "string" ? input.model.trim() : input.model;
        });

      const fileInput = this.inputs.find((input) => input.type == "file");

      if (!fileInput.model) {
        body[fileInput.id] = null;
      }

      const result = await this.postApparatus(body);

      if (fileInput.model && fileInput.model[0] instanceof File) {
        let formData = new FormData();

        let fileModel = {
          ref: "apparatus",
          refId: result.id,
          field: "images",
          files: fileInput.model,
        };

        Object.keys(fileModel).forEach((key) => {
          if (key != "files") {
            formData.append(key, fileModel[key]);
          } else {
            fileModel[key].forEach((file) => {
              formData.append("files", file);
            });
          }
        });

        const uploadImage = await this.API.postForm("upload", formData);

        if (uploadImage.status == 200) {
          result["images"] = uploadImage;
        }
      }

      this.$parent.$emit("toggleSpinner", false);

      this.$parent.result.data = result;

      this.$parent.close();
    },
    postApparatus(body) {
      return new Promise((resolve, reject) => {
        let url = "apparatuses";
        let method = "post";

        if (this.apparatus) {
          url += `/${this.apparatus._id}`;
          method = "put";
        } else {
          body.sharerinformation = this.sharer;
          body.site = this.site;
        }

        this.API[method](url, body)
          .then((retVal) => {
            return resolve(retVal.data);
          })
          .catch(() => {
            this.$parent.$emit("toggleSpinner");

            return reject();
          });
      });
    },
    inputChanged(e) {
      let input = this.inputs.find((i) => i.id == e.id);

      input.model = e.model;
    },
  },
  mounted() {
    if (this.apparatus) {
      this.inputs = this.inputs.map((input) => {
        if (this.apparatus[input.id]) {
          if (typeof this.apparatus[input.id] == "object") {
            input.model = this.apparatus[input.id]._id;
          } else {
            input.model = this.apparatus[input.id];
          }
        }

        return input;
      });
    }
  },
};
</script>