<template>
  <div id="view-site-sharer" class="mt-3 pt-3 pb-3">
    <sharer-breakdown
      :sharers="sharers"
      :apparatusTypes="options.apparatustype"
      class="content-item py-4 mb-4 px-0 override-bg bg-white"
      v-if="sharers.length > 0"
    />
    <div class="content-item" v-if="!sharerApparatus">
      <div
        class="text-right px-0 pb-4 d-flex justify-content-between align-items-center"
      >
        <p class="text-muted mb-0 font-weight-bold">
          <i class="fas fa-wifi mr-2"></i>
          SHARERS
        </p>
        <button
          class="btn btn-sm btn-primary rounded-pill px-3 font-weight-medium"
          @click="openSharerModal(true)"
        >
          + Add new sharer
        </button>
      </div>
      <div class="table-responsive">
        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <th class="fit-cell" rowspan="2"></th>
              <th class="fit-cell text-center" rowspan="2">Telco</th>
              <th class="fit-cell" rowspan="2">Telco ID</th>
              <th class="fit-cell" rowspan="2">Commencement<br />Date</th>
              <th class="fit-cell" rowspan="2">Tier</th>
              <th class="fit-cell" rowspan="2">Basic Rental</th>
              <th class="fit-cell" rowspan="2">Monthly VO</th>
              <th class="fit-cell" rowspan="2">Land Rental<br />Excess</th>
              <th class="fit-cell" rowspan="2">Add Apparatus<br />Fee</th>
              <th class="fit-cell" rowspan="2">Total Rental</th>
              <th class="fit-cell" rowspan="2">No. of<br />Antenna</th>
              <th class="fit-cell text-center" colspan="6">MicroWave</th>
              <th class="fit-cell" rowspan="2">No. of<br />RRU</th>
              <th class="fit-cell" rowspan="2">AROW Fiber</th>
              <th class="fit-cell" rowspan="2">Power Utility<br />User</th>
              <th class="fit-cell" rowspan="2"></th>
            </tr>
            <tr>
              <th class="fit-cell text-center">0.3</th>
              <th class="fit-cell text-center">0.6</th>
              <th class="fit-cell text-center">1.2</th>
              <th class="fit-cell text-center">1.8</th>
              <th class="fit-cell text-center">2.4</th>
              <th class="fit-cell text-center">3.0</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="sharer in sharers"
              :key="sharer._id"
              class="cursor-pointer"
              @click="selectSharer(sharer)"
            >
              <td class="fit-cell text-nowrap">
                <button
                  class="btn btn-link text-decoration-none px-0"
                  @click.stop="editSharer(sharer)"
                >
                  <i class="fas fa-edit"></i>
                </button>
              </td>
              <td class="fit-cell">
                <div
                  class="cell-image text-center shadow-none"
                  :class="{ 'bg-white': sharer.telco && sharer.telco.logo }"
                >
                  <img
                    class="full-image"
                    :src="
                      Helper.formatMediaUrl(sharer.telco && sharer.telco.logo)
                    "
                    v-if="sharer.telco && sharer.telco.logo"
                  />
                  <img
                    class="w-auto"
                    src="@/assets/images/no-image.png"
                    v-else
                  />
                </div>
              </td>
              <td class="fit-cell">{{ sharer.telco_id }}</td>
              <td class="fit-cell text-nowrap">
                {{ Helper.formatDate(sharer.commencement_date) }}
              </td>
              <td class="fit-cell">{{ sharer.tier }}</td>
              <td class="fit-cell text-nowrap">
                {{
                  sharer.basic_rental && Helper.formatMoney(sharer.basic_rental)
                }}
              </td>
              <td class="fit-cell text-nowrap">
                {{ sharer.monthly_vo && Helper.formatMoney(sharer.monthly_vo) }}
              </td>
              <td class="fit-cell text-nowrap">
                {{
                  sharer.land_rental_excess &&
                  Helper.formatMoney(sharer.land_rental_excess)
                }}
              </td>
              <td class="fit-cell text-nowrap">
                {{
                  sharer.add_apparatus_fee &&
                  Helper.formatMoney(sharer.add_apparatus_fee)
                }}
              </td>
              <td class="fit-cell text-nowrap">
                {{
                  sharer.total_rental && Helper.formatMoney(sharer.total_rental)
                }}
              </td>
              <td class="fit-cell text-center">{{ noOfAntennas(sharer) }}</td>
              <td class="fit-cell text-center">{{ sharer.mw_03 || 0 }}</td>
              <td class="fit-cell text-center">{{ sharer.mw_06 || 0 }}</td>
              <td class="fit-cell text-center">{{ sharer.mw_12 || 0 }}</td>
              <td class="fit-cell text-center">{{ sharer.mw_18 || 0 }}</td>
              <td class="fit-cell text-center">{{ sharer.mw_24 || 0 }}</td>
              <td class="fit-cell text-center">{{ sharer.mw_30 || 0 }}</td>
              <td class="fit-cell text-center">{{ noOfRRUs(sharer) }}</td>
              <td class="fit-cell text-center">
                {{ sharer.arow_fiber ? "Yes" : "No" }}
              </td>
              <td class="fit-cell text-center">
                {{ sharer.power_utility_user ? "Yes" : "No" }}
              </td>
              <td class="fit-cell text-nowrap">
                <button
                  class="btn btn-link text-danger text-decoration-none px-0"
                  @click.stop="deleteSharer(sharer)"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td>
            </tr>
            <tr v-if="sharers.length < 1">
              <td class="text-center text-muted" colspan="20">
                <i>No sharer found</i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="content-item override-bg bg-white" v-else>
      <div
        class="text-right px-0 pb-4 d-flex justify-content-between align-items-center"
      >
        <div>
          <button class="btn btn-link" @click="selectSharer()">
            <i class="fas fa-arrow-left mr-2"></i>Back
          </button>
          <span class="text-muted font-weight-bold">
            <i class="fas fa-satellite-dish mr-2"></i>
            APPARATUS
          </span>
        </div>
        <button
          class="btn btn-sm btn-primary rounded-pill px-3 font-weight-medium"
          @click="openApparatusModal(true)"
        >
          + Add new apparatus
        </button>
      </div>
      <div class="table-responsive">
        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <th class="fit-cell"></th>
              <th class="fit-cell text-center">Sharer</th>
              <th class="fit-cell text-center">Image</th>
              <th>Name</th>
              <th>Type</th>
              <th>Location</th>
              <th class="fit-cell"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="apparatus in sortedApparatus.apparatus"
              :key="apparatus._id"
            >
              <td class="fit-cell text-nowrap">
                <button
                  class="btn btn-link text-decoration-none px-0"
                  @click.stop="editApparatus(apparatus)"
                >
                  <i class="fas fa-edit"></i>
                </button>
              </td>
              <td class="fit-cell">
                <div
                  class="cell-image text-center shadow-none"
                  :class="{
                    'bg-white':
                      sortedApparatus.telco && sortedApparatus.telco.logo,
                  }"
                >
                  <img
                    class="full-image"
                    :src="
                      Helper.formatMediaUrl(
                        sortedApparatus.telco && sortedApparatus.telco.logo
                      )
                    "
                    v-if="sortedApparatus.telco && sortedApparatus.telco.logo"
                  />
                  <img
                    class="w-auto"
                    src="@/assets/images/no-image.png"
                    v-else
                  />
                </div>
              </td>
              <td class="fit-cell">
                <div
                  class="cell-image text-center shadow-none"
                  :class="{
                    'bg-white': apparatus.images && apparatus.images.length > 0,
                  }"
                >
                  <img
                    class="full-image"
                    :src="Helper.formatMediaUrl(apparatus.images[0])"
                    v-if="apparatus.images && apparatus.images.length > 0"
                  />
                  <img
                    class="w-auto"
                    src="@/assets/images/no-image.png"
                    v-else
                  />
                </div>
              </td>
              <td class="text-nowrap">{{ apparatus.name }}</td>
              <td class="text-nowrap">
                {{ displayApparatusType(apparatus.apparatustype) }}
              </td>
              <td class="text-nowrap">
                {{ Helper.formatEnumString(apparatus.location) }}
              </td>
              <td class="fit-cell text-nowrap">
                <button
                  class="btn btn-link text-danger text-decoration-none px-0"
                  @click.stop="deleteApparatus(apparatus)"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td>
            </tr>
            <tr
              v-if="
                !sharerApparatus ||
                !sharerApparatus.apparatus ||
                sharerApparatus.apparatus.length < 1
              "
            >
              <td class="text-center text-muted" colspan="20">
                <i>No apparatus found</i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal
      id="sharerInfoModal"
      v-if="showModal.sharerInfo"
      :title="`${currentSharer ? 'Edit' : 'Add new'} sharer`"
      @toggleSpinner="toggleSpinner"
      @close="openSharerModal"
    >
      <sharer-info-form :options="options" :sharer="currentSharer" />
    </modal>
    <modal
      id="apparatusModal"
      v-if="showModal.apparatus"
      :title="`${
        sharerApparatus &&
        sharerApparatus.apparatus &&
        sharerApparatus.apparatus.length > 0
          ? 'Edit'
          : 'Add new'
      } apparatus`"
      @toggleSpinner="toggleSpinner"
      @close="openApparatusModal"
    >
      <apparatus-form
        :options="options"
        :apparatus="currentApparatus"
        :sharer="sharerApparatus.sharerId"
        :site="sharerApparatus.siteId"
      />
    </modal>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Modal from "@/components/Modal";
import SharerBreakdown from "@/components/SharerBreakdown";
import SharerInfoForm from "@/components/forms/SharerInfo";
import ApparatusForm from "@/components/forms/Apparatus";

export default {
  name: "site-sharer-view",
  props: ["site"],
  components: {
    Modal,
    SharerInfoForm,
    SharerBreakdown,
    ApparatusForm,
  },
  data() {
    return {
      showModal: {
        sharerInfo: false,
        apparatus: false,
      },
      currentSharer: null,
      sharerApparatus: null,
      currentApparatus: null,
      sharers: [],
      options: {
        telco: [],
        apparatustype: [],
        location: ["Leg_1", "Leg_2", "Leg_3", "Leg_4"],
      },
    };
  },
  watch: {
    site: {
      async handler() {
        if (this.site && this.site._id) {
          await this.getTelcos();
          await this.getApparatusTypes();
          await this.getSharers();
        } else {
          this.sharers = [];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    sortedApparatus() {
      if (this.sharerApparatus) {
        this.sharerApparatus.apparatus.sort((a, b) => {
          return a.apparatustype - b.apparatustype;
        });

        return this.sharerApparatus;
      } else {
        return null;
      }
    },
    noOfAntennas() {
      return (sharer) => {
        if (sharer && sharer.apparatuses) {
          return sharer.apparatuses.filter((a) => a.type == "Antenna").length;
        } else {
          return 0;
        }
      };
    },
    noOfRRUs() {
      return (sharer) => {
        if (sharer && sharer.apparatuses) {
          return sharer.apparatuses.filter((a) => a.type == "RRU").length;
        } else {
          return 0;
        }
      };
    },
    displayApparatusType() {
      return (id) => {
        const apparatusType =
          typeof id == "object"
            ? id
            : this.options.apparatustype.find((type) => type._id == id);

        return apparatusType ? apparatusType.name : "";
      };
    },
  },
  methods: {
    selectSharer(sharer) {
      if (sharer) {
        this.sharerApparatus = {
          sharerId: sharer._id,
          siteId: sharer.site._id,
          telco: sharer.telco,
          apparatus: sharer.apparatuses ? sharer.apparatuses : [],
        };
      } else {
        this.sharerApparatus = null;
      }
    },
    editSharer(sharer) {
      this.currentSharer = sharer;

      this.openSharerModal(true);
    },
    openSharerModal(result) {
      if (typeof result == "boolean") {
        this.showModal.sharerInfo = result;
      } else if (typeof result == "object") {
        this.showModal.sharerInfo = result.show;

        if (result.data) {
          if (this.currentSharer) {
            const sharerIndex = this.sharers.findIndex(
              (sharer) => sharer._id == result.data._id
            );

            if (sharerIndex > -1) {
              this.$set(this.sharers, sharerIndex, result.data);
            }
          } else {
            this.sharers.push(result.data);
          }
        }

        this.currentSharer = null;
      }
    },
    editApparatus(apparatus) {
      this.currentApparatus = apparatus;

      this.openApparatusModal(true);
    },
    openApparatusModal(result) {
      if (typeof result == "boolean") {
        this.showModal.apparatus = result;
      } else if (typeof result == "object") {
        this.showModal.apparatus = result.show;

        if (result.data) {
          if (this.currentApparatus) {
            const apparatusIndex = this.sharerApparatus.apparatus.findIndex(
              (apparatus) => apparatus._id == result.data._id
            );

            if (apparatusIndex > -1) {
              this.sharerApparatus.apparatus[apparatusIndex] = result.data;

              const sharer = this.sharers.find(
                (sharer) => sharer._id == this.sharerApparatus.sharerId
              );

              if (sharer) {
                sharer.apparatuses = this.sharerApparatus.apparatus;

                const sharerIndex = this.sharers.findIndex(
                  (s) => s._id == sharer._id
                );

                if (sharerIndex > -1) {
                  this.$set(this.sharers, sharerIndex, sharer);
                }
              }
            }
          } else {
            this.sharerApparatus.apparatus.push(result.data);
          }
        }

        this.currentApparatus = null;
      }
    },
    toggleSpinner(e) {
      this.$emit("toggleSpinner", e);
    },
    deleteApparatus(apparatus) {
      Swal.fire({
        html: `<p>Confirm delete this apparatus?</p><p class='font-weight-bold'>${apparatus.name}</p>`,
        icon: "question",
        confirmButtonText: "Confirm",
        showCancelButton: true,
        buttonsStyling: false,
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-sm btn-danger",
          cancelButton: "btn btn-sm btn-light",
          actions: "d-flex justify-content-between align-items-center",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.toggleSpinner(true);

          await this.API.del(`apparatuses/${apparatus._id}`);

          this.sharerApparatus.apparatus = this.sharerApparatus.apparatus.filter(
            (s) => s._id != apparatus._id
          );

          this.sharers = this.sharers.map((sharer) => {
            if (sharer._id == apparatus.sharerinformation) {
              sharer.apparatuses = this.sharerApparatus.apparatus;
            }

            return sharer;
          });

          this.toggleSpinner(false);
        }
      });
    },
    deleteSharer(sharer) {
      Swal.fire({
        html: `<p>Confirm delete this sharer?</p><p class='font-weight-bold'>${sharer.telco_id}</p>`,
        icon: "question",
        confirmButtonText: "Confirm",
        showCancelButton: true,
        buttonsStyling: false,
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-sm btn-danger",
          cancelButton: "btn btn-sm btn-light",
          actions: "d-flex justify-content-between align-items-center",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.toggleSpinner(true);

          await this.API.del(`sharerinformations/${sharer._id}`);

          this.sharers = this.sharers.filter((s) => s._id != sharer._id);

          this.toggleSpinner(false);
        }
      });
    },
    async getSharers() {
      this.toggleSpinner(true);

      return this.API.get(`sharerinformations?site=${this.site._id}`)
        .then((retVal) => {
          this.sharers = retVal.data;

          this.sharers.sort((a, b) => {
            return a.telco && b.telco && b.telco.name < a.telco.name;
          });

          this.toggleSpinner(false);

          return;
        })
        .catch(() => {
          this.toggleSpinner(false);

          return;
        });
    },
    async getTelcos() {
      this.toggleSpinner(true);

      return this.API.get("telcos?_sort=name:ASC")
        .then((retVal) => {
          this.options.telco = retVal.data;

          this.toggleSpinner(false);

          return;
        })
        .catch(() => {
          this.toggleSpinner(false);

          return;
        });
    },
    async getApparatusTypes() {
      this.toggleSpinner(true);

      return this.API.get("apparatustypes?_sort=name:ASC")
        .then((retVal) => {
          this.options.apparatustype = retVal.data;

          this.toggleSpinner(false);

          return;
        })
        .catch(() => {
          this.toggleSpinner(false);

          return;
        });
    },
  },
};
</script>