<template>
  <div class="form">
    <div class="row pb-4">
      <template v-for="input in inputs">
        <component
          :is="`${input.type}-input`"
          :key="input.id"
          :input="input"
          :options="options && options[input.id]"
          :class="`${
            typeof input.col != 'undefined'
              ? input.col === 0
                ? 'col'
                : 'col-'.concat(input.col)
              : 'col-12'
          }`"
          @change="inputChanged"
        />
      </template>
    </div>
    <div
      class="pt-3 border-top d-flex justify-content-between align-items-center"
    >
      <button class="btn btn-light px-4" @click="close()">Cancel</button>
      <button class="btn btn-success px-4" @click="save()">
        <i class="fas fa-save mr-2"></i>
        Save
      </button>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/inputs/Text";
import TextareaInput from "@/components/inputs/Textarea";
import SelectInput from "@/components/inputs/Select";
import SwitchInput from "@/components/inputs/Switch";
import CalendarInput from "@/components/inputs/Calendar";

export default {
  name: "sharer-info-form-component",
  props: ["options", "sharer"],
  components: {
    TextInput,
    TextareaInput,
    SelectInput,
    SwitchInput,
    CalendarInput,
  },
  data() {
    return {
      inputs: [
        {
          type: "select",
          label: "Telco",
          id: "telco",
          model: null,
          col: 6,
        },
        {
          type: "text",
          label: "Telco ID",
          id: "telco_id",
          model: null,
          col: 6,
        },
        {
          type: "text",
          label: "Tier",
          id: "tier",
          model: null,
          col: 6,
        },
        {
          type: "calendar",
          label: "Commencement Date",
          id: "commencement_date",
          model: null,
          col: 6,
        },
        // {
        //   type: "text",
        //   label: "No. of Antennas",
        //   id: "no_of_antennas",
        //   model: null,
        //   col: 4,
        // },
        // {
        //   type: "text",
        //   label: "No. of RRU",
        //   id: "no_of_rrus",
        //   model: null,
        //   col: 4,
        // },
        {
          type: "text",
          label: "MW 0.3",
          id: "mw_03",
          model: null,
          col: 4,
        },
        {
          type: "text",
          label: "MW 0.6",
          id: "mw_06",
          model: null,
          col: 4,
        },
        {
          type: "text",
          label: "MW 1.2",
          id: "mw_12",
          model: null,
          col: 4,
        },
        {
          type: "text",
          label: "MW 1.8",
          id: "mw_18",
          model: null,
          col: 4,
        },
        {
          type: "text",
          label: "MW 2.4",
          id: "mw_24",
          model: null,
          col: 4,
        },
        {
          type: "text",
          label: "MW 3.0",
          id: "mw_30",
          model: null,
          col: 4,
        },
        {
          type: "switch",
          label: "AROW Fiber",
          id: "arow_fiber",
          model: false,
          col: 6,
        },
        {
          type: "switch",
          label: "Power Utility User",
          id: "power_utility_user",
          model: false,
          col: 6,
        },
        {
          type: "text",
          label: "Basic Rental (RM)",
          id: "basic_rental",
          model: null,
          col: 6,
        },
        {
          type: "text",
          label: "Monthly VO (RM)",
          id: "monthly_vo",
          model: null,
          col: 6,
        },
        {
          type: "text",
          label: "Land Rental Excess (RM)",
          id: "land_rental_excess",
          model: null,
          col: 6,
        },
        {
          type: "text",
          label: "Add Apparatus Fee (RM)",
          id: "add_apparatus_fee",
          model: null,
          col: 6,
        },
        {
          type: "text",
          label: "Total Rental (RM)",
          id: "total_rental",
          model: null,
          col: 6,
        },
      ],
    };
  },
  methods: {
    close() {
      this.$parent.close();
    },
    async save() {
      this.$parent.$emit("toggleSpinner", true);

      let body = {};

      this.inputs.forEach((input) => {
        body[input.id] =
          typeof input.model == "string" ? input.model.trim() : input.model;
      });

      const sharer = await this.postSharer(body);

      this.$parent.$emit("toggleSpinner", false);

      this.$parent.result.data = sharer;

      this.$parent.close();
    },
    postSharer(body) {
      return new Promise((resolve, reject) => {
        let url = "sharerinformations";
        let method = "post";

        if (this.sharer) {
          url += `/${this.sharer._id}`;
          method = "put";
        } else {
          body.site = this.$route.params.id;
        }

        this.API[method](url, body)
          .then((retVal) => {
            return resolve(retVal.data);
          })
          .catch(() => {
            this.$parent.$emit("toggleSpinner");

            return reject();
          });
      });
    },
    inputChanged(e) {
      let input = this.inputs.find((i) => i.id == e.id);

      input.model = e.model;
    },
  },
  mounted() {
    if (this.sharer) {
      this.inputs = this.inputs.map((input) => {
        if (this.sharer[input.id]) {
          if (typeof this.sharer[input.id] == "object") {
            input.model = this.sharer[input.id]._id;
          } else {
            input.model = this.sharer[input.id];
          }
        }

        return input;
      });
    }
  },
};
</script>